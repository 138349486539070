body {
    background-color: #F7F7F7;
    margin: 0;
    margin-top: -20px !important;
}

/* @media screen and (min-width: 1500px) {
    body {
        zoom: 1.4;
        -moz-transform: scale(1.4);
        -moz-transform-origin: 0 0;
    }
}

@media screen and (min-width: 1800px) {
    body {
        zoom: 1.7;
        -moz-transform: scale(1.7);
        -moz-transform-origin: 0 0;
    }
}

@media screen and (min-width: 1900px) {
    body {
        zoom: 2;
        -moz-transform: scale(2);
        -moz-transform-origin: 0 0;
    }
}

@media screen and (min-width: 2300px) {
    body {
        zoom: 2.5;
        -moz-transform: scale(2.5);
        -moz-transform-origin: 0 0;
    }
} */

@media screen and (min-width: 2700px) {
    body {
        zoom: 2.8;
        -moz-transform: scale(2.8);
        -moz-transform-origin: 0 0;
    }
}


.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    transition: display 0.3s ease-in-out;
}

.modal-show {
    display: block;
}

.modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    animation: modalopen 0.5s;
}

.title-tag {
    color: black;
    font-size: large;
    font-weight: 500;
}

.title-tag-mini {
    color: black;
    font-size: medium;
    font-weight: 400;
}



@keyframes modalopen {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.open-modal-btn {
    margin: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #FF0000;
}

h1,
h2,
p,
ul,
li,
a,
form,
span {
    font-family: proxima-nova, sans-serif;
    color: #323232;
}

ul.navigation li {
    list-style-type: none;
    margin-bottom: 15px;
    background-color: white;
    border-radius: 3px;
}

ul.navigation {
    padding: 0;
    margin-top: 0;
    display: inline-block;
    width: 180px;
}

ul.navigation li a {
    color: #323232;
    font-weight: 400;
    text-decoration: none;
    padding: 20px;
    display: block;
}

.content {
    width: 360px;
    background-color: #FFF;
    padding: 30px;
    display: inline-block;
    border-radius: 5px;
    margin-bottom: 50px;
}

.contentSideInner {
    background-color: #FFF;
    padding: 30px;
    width: 220px;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-left: 25px;
}

.contentSideImageCrop {
    padding: 30px;
    width: 260px;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-left: 25px;
}

.contentSideImage {
    padding: 30px;
    width: 0px;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-left: 25px;
}

.imageBox {
    height: 179px;
    width: 200px;
    border-radius: 5px;
    object-fit: cover;
    cursor: pointer;
}

.imageBoxOverview {
    width: 280px;
}

.maxwidthText {
    width: 180px;
    display: inline-block;
}

.contentImageBox {
    padding: 0;
}

.editIcon {
    position: absolute;
    width: 35px;
    margin-left: -50px;
    margin-top: 130px;
    cursor: pointer;
    border-radius: 100px;
}

.editIconOverview {
    position: absolute;
    width: 35px;
    cursor: pointer;
    margin-left: 230px;
    margin-top: -50px;
    border-radius: 100px;
}

.imgFileInput {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inner {
    padding-left: 12px;
    padding-right: 12px;
    max-width: 940px;
    /* width: 100%; */
    margin: 40px auto 0;
}

/* @media(min-width: 1860px){
    .inner { max-width: 100%;}
} */

.outer {
    /* margin-left: 50%; */
    -webkit-animation: fadein 0.6s ease;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.6s ease;
    /* Firefox < 16 */
    -ms-animation: fadein 0.6s ease;
    /* Internet Explorer */
    -o-animation: fadein 0.6s ease;
    /* Opera < 12.1 */
    animation: fadein 0.6s ease;
}

.logoBar {
    height: 120px;
    background-color: #1D1D1D;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
}

.logo {
    /* margin-top: 28px; */
    width: 70px;
    text-align: start;
}

.logoHeader {
    text-align: center;
}


.titleBar {
    height: 80px;
    background-color: white;
}

.outerTitleBar {
    /* margin-left: 50%; */
}

.innerTitleBar {
    max-width: 940px;
    /* margin-left: -470px; */
    margin: 0 auto;
    padding-left: 12px;
    padding-right: 12px;
}

.innerTitleBar a {
    text-decoration: none;
}

.pageTitle {
    float: left;
    margin-top: 31px;
    font-size: 20px;
    margin-bottom: 0;
}

.yellowBtn {
    font-size: 14px;
    margin-top: 20px;
    padding: 12px 25px;
    background-color: #FFE000;
    border-radius: 4px;
}

.greenBtn {
    font-size: 14px;
    margin-top: 31px;
    margin-right: 20px;
    color: #323232;
    float: right;
}


.redBtn {
    font-size: 14px;
    margin-top: 31px;
    margin-right: 20px;
    color: #FF0000;
    float: right;
}

.greyBtn {
    font-size: 14px;
    margin-top: 31px;
    margin-right: 20px;
    color: rgb(177, 177, 177);
    float: right;
}

.icon-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #FFE000;
    color: #323232;
    font-size: 14px;
    cursor: pointer;
}

.icon {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    margin-bottom: 2px;
    /* Additional styles to position the icon */
    /* Example: background-position, background-repeat, etc. */
}


.tabTitle {
    margin-top: 10px;
    margin-bottom: 33px;
    font-size: 26px;
    line-height: 15px;
    font-weight: bold;
    color: rgb(0, 0, 0);

}

.boxShadow {
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
}

.contentTitle {
    font-size: 15pt;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 25px;
    color: #464646;

}

.noteTitle {
    font-size: 8pt;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: -10px;
    color: #323232b0;
}

.positionNote {
    font-size: 8pt;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 5px;
    color: #323232b0;

}

.contentSub {
    font-size: 11pt;
    color: #464646;
    margin-bottom: 10px;
}

.longInput {
    width: 270px;
    padding: 10px 10px;
    font-size: 11pt;
    font-weight: 400;
    border-radius: 3px;
    border: none;
    border: solid 1px #ccc;
    color: #323232;
}

.blockInput {
    padding: 10px 10px;
    width: 270px;
    height: 100px;
    font-size: 11pt;
    font-weight: 400;
    border-radius: 3px;
    border: none;
    border: solid 1px #ccc;
    color: #323232;
    resize: none;
}

.contactInput {
    margin-bottom: 15px;
    display: block;
}

.timeBox {
    margin-top: 8px;
}

.timeLabel {
    color: #464646;
    float: left;
    width: 4.1em;
    margin-right: 1.8em;
    margin-top: 9px;
    font-size: 11pt;
}

.timeLabelTop {
    color: #464646;
    float: left;
    width: 4.1em;
    margin-right: 1.8em;
    margin-top: 0px;
    font-size: 11pt;
}

.addIcon {
    width: 14px;
    margin-left: 6px;
    margin-top: 4px;
}

.addIconTop {
    width: 14px;
    margin-left: 6px;
}

.addIconDiv {}


.removeIcon {
    width: 14px;
    margin-left: 6px;
    vertical-align: middle;
}


.closedLabel {
    color: #ff4242;
    float: left;
    width: 4.1em;
    margin-right: 3.61em;
    margin-top: 3px;
    font-size: 11pt;

}

.closedLabelTop {
    margin-top: 0px;
}



.timeSheet {
    margin-bottom: 10px;
}

.timeAdd {
    float: right;
    width: 20px;
    margin-top: 1px;
}

.timeFromTo {
    float: left;
    color: #464646;
    width: 1em;
    margin-right: 1em;
    margin-top: 15px;
    font-size: 11pt;
}

.dayPickerDiv {
    width: 190px;
    display: inline-block;
}

.bottomDayPicker {
    margin-top: -10px;
}

.inputDiv {
    width: 120px;
    float: right;
}

.tempTimesDiv {
    width: 205px;
}

.floatLeft {
    float: left;
}

.floatRight {
    float: right;
}

.topTitle {
    margin-top: 0;
    color: #464646;

}

.map {
    margin-top: 15px;
    border-radius: 3px;
    width: 250px;
    height: 150px;
    background-color: rgb(228, 228, 228);
}

.categorySelect {
    font-family: proxima-nova;
    font-size: 11pt;
    width: 292px;
    color: #7E7E7E;
    margin-bottom: 35px;
}

.categorySelectEvent {
    width: 372px;
}

.css-1uccc91-singleValue {
    color: #343434 !important;
}

.active {
    font-weight: bold !important;
    border-radius: 3px;
}

.eventsTitle {
    font-size: 17pt;
    font-weight: 500;
    margin-bottom: 15px;
    margin-top: 0;
    color: #464646;

}

.eventsTime {
    font-size: 11pt;
    font-weight: 400;
    margin-bottom: 10px;
    color: #464646;
}

.eventsMenu {
    font-size: 9pt;
    font-weight: 100;
    color: #464646;
    margin-bottom: 0;
}

.eventsInfo {
    float: left;
}

.eventsImage {
    margin: -30px;
    margin-bottom: -34px;
    float: right;
}

.eventsDiv {
    margin-bottom: 40px;
}

.contentWide {
    width: 665px;
    display: inline-block;
}

.innerEventLeft {
    width: 405px;
    float: left;
}

.innerEventRight {
    width: 255px;
    float: left;
}

.eventInput {
    width: 350px;
    margin-bottom: 15px;
    display: block;
    font-family: proxima nova;
}

.eventSub {
    font-size: 11pt;
    color: #464646;
    margin-bottom: 10px;
    margin-top: 20px;
}

.drinkSelect {
    float: left;
    width: 310px;
    margin-bottom: 5px;
    font-size: 11pt;
}

.drinkDiv {
    display: block;
}

.drinkPrice {
    text-align: center;
    margin-left: 10px;
    width: 30px;
    float: left;
    padding: 10px 10px;
    font-size: 9pt;
    font-weight: 400;
    border-radius: 3px;
    border: none;
    border: solid 1px #ccc;
    color: #7E7E7E;
}

.eventDayDiv {
    float: left;
    width: 245px;
    height: 40px;
    margin-bottom: 15px;
    margin-top: -10px;
}

#dayPickerMulti {
    display: none;
}

#weekdayPickerMulti {
    display: none;
}

.sameLine {
    display: inline;
    margin-left: 20px;
}

.sameLineWeekday {
    display: inline;
    margin-left: 10px;
}

.barImg {
    border-radius: 0px 5px 5px 0px;
    width: 200px;
    height: 144px;
    margin-bottom: -1px;
    object-fit: cover;
}

.iconEvents {
    margin-left: 10px;
    width: 40px;
    border-radius: 100px;
}

.iconEventsDiv {
    float: right;
}

.iconEventsDivMobile {
    float: right;
    display: none;
}

.iconEventsDivMobile a.linkStyle {
    background-color: #FFE000;
    border-radius: 50px;
    padding: 0 15px 0 0px;
    display: flex;
    align-items: center;
}

.iconEventsDivMobile a.linkStyle img.iconEvents {
    margin-left: 0;
}

.iconEdit {
    position: absolute;
    margin-left: 23px;
    margin-top: 57px;
    width: 20px;
}

.iconAddDiv {
    margin-left: 265px;
}

.iconAdd {
    width: 20px;
    margin-left: 5px;
}

.linkStyle {
    cursor: pointer;
}

.event {
    margin-top: 0px;

}

.underline {
    text-decoration: underline;
}

.otherDiv {
    width: 270px;
    margin-bottom: 35px;
}

.toggleTag {
    font-family: proxima-nova;
    font-size: 11pt;
    color: #464646;
}

.toggleBtn {
    margin-top: -2px;
    float: right;
}

.toggleDiv {
    margin-top: 20px;
}

.toggleDistance {
    height: 10px;
}

.lineBreak {
    margin-top: 12px;
    margin-bottom: 12px;
    border: solid 0.5px;
    border-color: #D3D3D3;
}

.lineBreakExtend {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: -30px;
    margin-right: -30px;
    border: solid 0.5px;
    border-color: #D3D3D3;
}

.billingInput {
    width: 400px;
    display: inline-block;
}

.infoDiv {
    width: 200px;
    display: inline-block;
    vertical-align: top;
    margin-left: 20px;
    padding-left: 10px;
    border-left: 1px solid;
    border-color: #D3D3D3;
}

.infoText {
    font-size: 10pt;
    color: #727272;
}

.cardDiv {
    font-size: 11pt;
    color: #464646;
    border: 1px solid;
    border-color: #D3D3D3;
    border-radius: 3px;
    margin-bottom: 30px;
}

.cardInfoDiv {
    display: inline-block;
    margin-right: 10px;
    padding: 15px;
}

.expireDiv {
    display: inline-block;
    border-left: 1px solid;
    border-color: #D3D3D3;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.cardBtnDiv {
    margin-bottom: 45px;
}

.invoiceOuter {
    border: 1px solid;
    border-color: #D3D3D3;
    font-size: 11pt;
    color: #464646;
}

.invoiceBox {
    border-left: 1px solid;
    border-color: #D3D3D3;
    display: inline-block;
    padding: 7px;
}

.box1 {
    border-left: none;
    width: 180px;
}

.box2 {
    width: 200px;
}

.box3 {
    width: 100px;
}

.box4 {}

.subDistribution {
    display: flex;
    justify-content: space-around;
}

.subOuter {
    text-align: center;
    width: 260px;
    background-color: #F4F4F4;
    border-radius: 5px;
    padding-bottom: 35px;
}

.subInner {
    width: 210px;
    padding: 20px;
    text-align: center;
    display: inline-block;
    margin-bottom: 10px;
}

.subTitleDiv {
    padding: 20px;
    background-color: #1D1D1D;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 20px;
}

.subTitle {
    font-size: 14pt;
    font-weight: 400;
    color: #FFE000;
}

.costTitle {
    font-size: 24pt;
    font-weight: 500;
    margin-bottom: 12.5px;
}

.subText {
    font-size: 9pt;
    font-weight: 400;
    margin-bottom: 20px;
}

.perkDiv {
    text-align: left;
}

.perkText {
    font-size: 9pt;
    display: inline;
}

.perkRow {
    margin-bottom: 5px;
}

.infoIcon {
    width: 17px;
    float: right;
}

.monthSpan {
    font-size: 12pt;
    font-weight: 400;
}

.unactive {
    color: #A5A5A5;
}

.subBtn {
    font-size: 10pt;
    border-radius: 100px;
    font-weight: 400;
}

.currentBtn {
    font-weight: 500;
    background-color: white;
    border: 2px solid #FFE000;
}

.outerWarning {
    display: none;
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgb(48, 48, 48, 0.5);
    z-index: 1000000;
    top: 0;
    left: 0;
}

.innerWarning {
    margin: 0 auto;
    margin-top: 30vh;
    background-color: white;
    width: 300px;
    padding: 30px 30px;
    border-radius: 3px;
}

.modalcontent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.modalcontent .innerWarning {
    margin-top: 0px;
}

.warningButton {
    padding: 10px 20px;
    border: none;
    background-color: #FFE000;
    margin-right: 10px;
    border-radius: 100px;
    cursor: pointer;
}

.warningText {
    margin: 0;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #464646;

}

.goBackBtn {
    background-color: rgb(213, 213, 213);
}

.deleteButton {
    float: right;
    border-radius: 100px;
    cursor: pointer;
}

.deleteIcon {
    width: 40px;
    border-radius: 100px;
    margin: 0;
    background-color: white;
}

.charCount {
    position: absolute;
    font-size: 10pt;
    margin-left: -90px;
    margin-top: 140px;
    background-color: white;
    color: #D3D3D3;
}

.drinkEmpty {
    color: #7E7E7E;
    text-align: center;
    width: 370px;
    text-decoration: underline;
}

.displayNone {
    display: none;
}

.weekdaySelect {
    width: 150px;
    margin-top: 15px;
    display: inline-block;
}

.rc-time-picker-input {
    padding: 5px 5px;
    text-align: center;
    width: 50px;
    font-size: 9pt;
    font-weight: 400;
    border-radius: 3px;
    border: solid 1px #ccc;
    color: #7E7E7E;
    font-family: proxima-nova, sans-serif;
}

.rc-time-picker-clear {
    display: none !important;
}

.eventTimeDiv {
    color: #7E7E7E;
}

.invsButton {
    color: blue;
}

.active {
    visibility: hidden;
    display: none;
    position: absolute;
}

.navSelect {
    visibility: initial !important;
    display: block !important;
    position: initial !important;
}

.dropdownMobile {
    display: none;
}

.scheduleDiv {
    margin-bottom: 30px;
}

.billingInfo {
    margin: 0;
    font-size: 9.5pt;
}

.outerDashboard {
    margin-left: 50%;
}

.innerDashboard {
    width: 940px;
    margin-left: -470px;
    text-align: space-between;
}

.barSelectDashboard {
    margin-right: 25px;
    vertical-align: top;
    display: inline-block;
    cursor: pointer;
    margin-top: 30px;
    -webkit-animation: fadein 0.6s ease;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.6s ease;
    /* Firefox < 16 */
    -ms-animation: fadein 0.6s ease;
    /* Internet Explorer */
    -o-animation: fadein 0.6s ease;
    /* Opera < 12.1 */
    animation: fadein 0.6s ease;
}

.eventCat {
    font-weight: 300;
}

.eventsPrompt {
    text-decoration: underline;
    color: #464646;

}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Firefox < 16 */

@-moz-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Internet Explorer */

@-ms-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */

@-o-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.barRoute {
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 30px;
}

.barImgDashboard {
    width: 220px;
    height: 180px;
    object-fit: cover;
    border-radius: 5px;
}

.barSelectTitle {
    color: #323232;
    font-size: 15pt;
    margin: 0;
    margin-top: 10px;
}

.barSelectAddress {
    color: #959595;
    font-size: 11pt;
    margin: 0;
    margin-top: 6px;
}

.offCanvas,
.hamburger {
    display: none;
}

.settingsNav {
    display: initial;
    /* float: right;
    margin-top: 50px;
    margin-right: 35px; */
    /* right: 0; */
    /* right: 35px; */
    /* position: absolute; */
}

.settingsToggle {
    background: none;
    border: none;
    color: white;
    padding: 0;
    font-size: 11pt;
    cursor: pointer;
}

.arrowDownWhite {
    width: 13px;
    margin-left: 13px;
    margin-top: 7px;
    vertical-align: top;
}

.settingsDrop {
    margin-left: 0px;
    margin-top: 8px;
}

.settingsItem {
    line-height: 13pt;
    font-size: 11pt;
    display: block;
    color: rgb(63, 63, 63);
    text-decoration: none;
    text-align: center;
    padding: 15px;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
}

.settingsItem:hover {
    color: rgb(126, 126, 126);
}

.item1 {
    border-radius: 3px 3px 0 0;
    border-bottom: rgb(215, 215, 215) 1px solid;
}

.item2 {
    border-radius: 3px 3px 3px 3px;
}

.eventLink {
    display: block;
    cursor: pointer;
}

.boxOpacity {
    opacity: 0.5
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.disabledToolTip {
    pointer-events: none;
}

.enabled {
    pointer-events: all;
    opacity: 1;
}

.eventBox {
    margin-bottom: 10px;
}

.contentEventsOuter {
    top: 0;
    width: 725px;
    ;
}

.saveDiv {
    width: 100%;
    position: fixed;
    text-align: center;
    left: 0;
    bottom: 0;
    margin-bottom: 35px;
    z-index: 10000;
}

.DayPickerInput-OverlayWrapper {
    z-index: 10001;
}

#saveButton {
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease all;
}

.saveButton {
    padding: 13px 37px 13px 37px;
    border-radius: 200px;
    cursor: pointer;
}

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: text-bottom;
    border: 0.2em solid #1D1D1D;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border 1s linear infinite;
    animation: spinner-border 1s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.spinnerDiv {
    width: 100%;
    text-align: center;
    margin-top: 100px;
}

.spinnerDivOnBoarding {
    margin-top: 20px;
}

.spinnerDivEvent {
    width: 100%;
    text-align: center;
    padding-top: 150px;
}

.menu-contentbox {
    position: relative;
    display: inline-block;
    width: 100%;
}

.menu-contentbox .menuInitial {
    /* top: initial;
    position: initial; */
    float: left;
    top: 0px;
    position: sticky;
    width: 180px;
}

.menu-contentbox .contentBox {
    width: calc(100% - 195px);
    padding-left: 15px;
    float: right;
}

.menu-contentbox .flexBox {
    display: flex;
}

.menu-contentbox .flexBox .content {
    width: 360px;
}

.menu-contentbox .flexBox .overview2ndDiv {
    width: calc(100% - 400px);
    float: unset;
    display: inline-block;
}

@media screen and (max-width: 1025px) {
    .menu-contentbox .flexBox {
        display: block;
    }

    .menu-contentbox .contentBox {
        width: 100%;
    }

    .menu-contentbox .flexBox .content {
        width: 100%;
    }

    .menu-contentbox .flexBox .overview2ndDiv {
        width: 100%;
    }
}

/* .menuFixed {
    top: 0;
    position: sticky;
    float: left;
} */

.hoursMargin {
    margin-left: 5px;
}

.grayedOut {
    background-color: rgb(235, 235, 235);
    color: rgb(201, 201, 201) !important;
    pointer-events: none;
    border-radius: 3px;
}

.grayedOutMobile {
    color: rgb(201, 201, 201) !important;
    pointer-events: none;
}

.noInvoicesText {
    font-size: 11pt;
    text-align: center;
    text-decoration: underline;
    color: #464646;

}

.trialMessageDiv {
    display: inline-block;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 30px;
}

.trialMessage {
    margin: 0;
    font-size: 12pt;
    text-align: center;
    text-decoration: underline;
}

.cropBox {
    border-radius: 5px;
}



.cropDiv {
    text-align: center;
}

.reactEasyCrop_Container {
    z-index: 100002;
}

.controlsDiv {
    position: absolute;
    width: 32%;
    bottom: 20px;
    left: 0;
    margin-left: 30%;
    margin-right: 30%;
    text-align: center;
    background-color: rgb(255, 255, 255);
    padding: 30px 4%;
    border-radius: 10px;
    z-index: 100003;
}

.cropButton {
    background-color: #FFE000;
    padding: 10px 30px;
    border-radius: 100px;
    cursor: pointer;
    font-size: 11pt;
}

.button {
    margin-top: 10px;
}

@media screen and (max-width: 1025px) {
    .controlsDiv {
        position: absolute;
        width: 82%;
        bottom: 20px;
        left: 0;
        margin-left: 5%;
        margin-right: 5%;
        text-align: center;
        background-color: rgb(255, 255, 255);
        padding: 30px 4%;
        border-radius: 10px;
        z-index: 100003;
    }

    .dropdownMobile {
        display: inline-block;
    }

    #menu {
        display: none !important;
    }

    .overview2ndDiv {
        float: none;
    }

    .contentBox {
        float: none;
        width: 100%;
    }

    .content {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
    }

    .contentSideInner {
        margin-left: 0;
        width: 100%;
        padding: 0;
    }

    .contactDiv {
        margin: 30px;
    }

    .otherDiv {
        width: initial;
        margin: 30px;
    }

    .positionDiv {
        margin: 30px;
    }

    .timeDiv {
        margin: 30px;
        margin-top: 20px;
        padding-top: 30px;
    }

    .innerTitleBar {
        /* width: 100%; */
        margin-left: 0;
    }

    .outerTitleBar {
        margin-left: 0;
    }

    .inner {
        margin-top: 20px;
        max-width: 100%;
        /* margin-left: 12px;
        margin-right: 12px; */
    }

    .outer {
        margin-left: 0;
    }

    .longInput {
        width: 90%;
    }

    .tabTitle {
        display: none;
    }

    .dropdown {
        width: 100%;
        margin: 0;
    }

    .dropdownToggle {
        float: left;
        font-family: proxima-nova, sans-serif;
        margin-top: 10px;
        font-size: 26px;
        line-height: 15px;
        font-weight: bold;
        padding: 0;
        user-select: none;
    }

    .dropdown-toggle {
        width: 100%;
        padding: 0;
        border: none;
        background: none;
        user-select: none;
        cursor: pointer;
    }

    .showButton {
        display: block;
        text-decoration: none;
        font-size: 17pt;
        line-height: 37px;
        text-align: left;
        margin-left: 5%;
    }

    .dropdownMenu {
        margin-bottom: 33px;
        padding-top: 0px;
        z-index: 4;
        line-height: 25pt;
        top: 0px;
        width: 100%;
    }

    .overlayShow {
        background-color: rgb(247, 247, 247);
        position: fixed;
        width: 100%;
        height: 100vh;
        padding-bottom: 5px;
        z-index: -1;
        top: -10px;
        overflow: hidden;
    }

    .overlayHide {
        visibility: hidden;
    }

    .eventTimeDiv,
    .eventDayDiv {
        display: block;
    }

    @keyframes spinUp {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(180deg);
        }
    }

    @keyframes spinDown {
        0% {
            transform: rotate(180deg);
        }

        100% {
            transform: rotate(0deg);
        }
    }

    .arrowDown {
        margin-top: 15px;
        float: right;
        width: 13px;
        animation: none;
    }

    /* .yellowBtn {
        right: 5%;
        float: right;
        position: absolute;
    } */
    .pageTitle {
        /* margin-left: 5%; */
    }

    .timeLabel,
    .timeLabelTop {
        margin-right: 3em;
    }

    .tempTimesDiv {
        display: inline-block;
        margin-bottom: 30px;
    }

    .scheduleDiv {
        display: inline-block;
        width: 270px;
        vertical-align: top;
    }

    .eventsInfo {
        margin: 30px;
        margin-right: 0;
    }

    .eventsImage {
        margin: 0;
    }

    .barImg {
        width: 144px;
    }

    .iconEdit {
        display: none;
    }

    .iconEventsDiv {
        display: none;
    }

    .iconEventsDivMobile {
        display: initial;
        position: fixed;
        bottom: 20px;
        right: 5%;
    }

    .eventsImage {
        float: none;
    }

    .barImg {
        float: right;
        width: 20%;
    }

    .innerEventLeft {
        margin: 25px;
        margin-bottom: 25px;
        float: none;
        width: initial;
    }

    .innerEventRight {
        margin: 25px;
        margin-top: 20px;
        width: initial;
    }

    .blockInput {
        width: 90%;
    }

    .drinkSelect {
        width: 81%;
    }

    .imageBox {
        width: 100%;
        height: 200px;
    }

    .eventTimeDiv,
    .eventDayDiv {
        float: none;
    }

    .eventDayDiv {
        margin-right: -80px;
    }

    .toggleDiv {
        width: 100%;
    }

    .toggleTag {
        font-size: 10pt;
    }

    .billingDiv {
        margin: 30px;
    }

    .infoDiv {
        border: none;
        margin-left: 0;
        padding-left: 0;
    }

    .subOuter {
        background-color: white;
        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
        -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    }

    .backgroundShift {
        background: none;
        box-shadow: none;
    }

    .categorySelect {
        width: initial;
    }

    .map {
        width: initial;
    }

    .outerDashboard {
        margin-left: 5%;
        margin-right: 5%;
    }

    .innerDashboard {
        max-width: initial;
        margin-left: 0;
        width: 100%;
    }

    .offCanvas {
        display: initial;
        position: fixed;
        right: -300px;
        top: 0;
        width: 300px;
        height: 100vh;
        background-color: #1D1D1D;
        z-index: 1000;
        transition: 0.5s ease all;
    }

    .offCanvasLinkDiv {
        margin-top: 110px;
        text-align: right;
    }

    .offCanvasLink {
        color: white;
        display: block;
        font-size: 20pt;
        margin-bottom: 10px;
    }

    .hamburger {
        display: initial;
        /* position: absolute; */
        top: 30px;
        right: 20px;
        z-index: 10001;
    }

    .logoSmall {
        position: absolute;
        bottom: 0;
        margin-bottom: 30px;
        width: 70px;
        margin-left: -30px;
    }

    .offCanvasLogoDiv {
        width: 100%;
        text-align: center;
    }

    .offCanvasInner {
        margin-right: 40px;
    }

    .settingsNav {
        display: none;
    }

    .contentSideImage {
        width: 100%;
        margin-left: 0;
        height: 200px;
        margin-bottom: 0px;
    }

    .editIconOverview {
        margin-left: -70px;
        margin-top: 130px;
        width: 47px;
    }

    .editIcon {
        margin-top: 140px;
        margin-left: -60px;
        width: 40px;
    }

    .saveButton {
        float: none;
        position: initial;
        font-size: 14pt;
        padding: 18px 48px 18px 48px;
    }

    .barImgDashboard {
        width: 100%;
        height: 250px;
    }

    .barSelectDashboard {
        margin-right: 0;
        width: 100%;
    }

    .hamburger-box {
        width: 32px;
    }

    .innerWarning {
        width: 7 0%;
    }

    .warningText {
        font-size: 13pt;
    }

    .warningButton {
        font-size: 11pt;
        padding: 15px 30px;
    }

    .contentEventsOuter {
        width: 100%;
    }

    .categorySelectEvent {
        width: 100%;
    }

    .drinkEmpty {
        width: 100%;
    }

    .imgEvent {
        width: 100%;
    }

    .eventInput {
        width: 90%;
        margin-right: 0;
    }

    .iconAddDiv {
        float: right;
        margin: 0;
    }

    .deleteButton {
        position: absolute;
        right: 0;
        margin-right: 5%;
    }

    .deleteIcon {
        box-shadow: none;
        background-color: transparent;
    }

    .drinkSelect {
        width: 83%;
        margin-bottom: 10px;
    }

    .drinkDiv {
        width: 100%;
    }

    .drinkPrice {
        margin-left: 3%;
        width: 13%;
        height: 35px;
        padding: 0;
    }

    .subBtn {
        float: none;
        position: initial;
        font-size: 14pt;
        padding: 17px 30px;
    }

    .subText {
        font-size: 12pt;
    }

    .perkTextDash {
        font-size: 12pt !important;
        line-height: 20pt;
    }

    .infoIconDash {
        width: 22px !important;
    }

    .redBtn,
    .greenBtn,
    .greyBtn {
        right: 20%;
        position: absolute;
    }
}

@media screen and (max-width: 730px) {

    .redBtn,
    .greenBtn,
    .greyBtn {
        visibility: hidden;
    }

    .deleteButton {
        position: absolute;
        right: 0;
        margin-right: 20px;
    }

    .subDistribution {
        display: block;
        margin-bottom: 0;
    }

    .subOuter {
        width: 100%;
        background-color: white;
        margin-bottom: 20px;
    }

    .subInner {
        width: 90%;
        padding: 0;
        margin-top: 20px;
    }

    .subBtn {
        margin-top: 40px;
        margin-right: 0;
    }

    .perkDiv {
        margin-bottom: 20px;
    }

    .eventsInfo {
        margin: 20px;
    }

    .eventsTitle {
        line-height: 22pt;
        max-width: 210px;
        font-size: 15pt;
        margin: 0;
        margin-bottom: 7px;
        color: #464646;

    }

    .eventsTime {
        font-size: 10pt;
        margin: 0;
        margin-bottom: 5px;
    }

    .eventsMenu {
        max-width: 175px;
        margin: 0;
        font-size: 10pt;
        margin-top: 7px;
    }

    .barImg {
        width: 25%;
        height: 127px;
    }

    .eventBox {
        margin-bottom: 10px;
    }
}


/* header css */
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 32px;
}

@media screen and (max-width: 1025px) {
    .hamburger {
        padding: 0;
    }

    .hamburger.is-active {
        position: absolute;
    }
}

@media(max-width: 991px) {
    .logoHeader-content {
        max-width: 400px;
    }

    .logoHeader-content img {
        width: 100%;
    }
}

@media(max-width: 767px) {
    .logoHeader-content {
        max-width: 300px;
    }
}

@media(max-width: 480px) {
    .logo-content {
        max-width: 40px;
    }

    .logo-content img {
        width: 100%;
    }
}

@media(max-width: 420px) {
    .logoHeader-content {
        max-width: 230px;
    }
}
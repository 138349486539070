/*Common Css*/
html body {
    font-family: 'proxima-nova', sans-serif !important;
    margin-top: 0 !important;
}

body p {
    font-family: 'proxima-nova', sans-serif !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 21.92px;
    color: #fff;
}

body h2 {
    font-family: 'proxima-nova', sans-serif !important;
    font-size: 30px;
    font-weight: 700;
    line-height: 36.54px;
    color: #fff;
}

body h3 {
    font-family: 'proxima-nova', sans-serif !important;
    font-size: 28px;
    font-weight: 700;
    line-height: 36.54px;
    color: #fff;
}

body h4 {
    font-family: 'proxima-nova', sans-serif !important;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.36px;
    color: #fff;
}

@media (max-width: 1440px) {
    body h2 {
        font-size: 26px;
        line-height: 33.54px;
    }

    body h3 {
        font-size: 24px;
        line-height: 33.54px;
    }

    body p {
        font-size: 18px;
    }
}

@media (max-width: 1199px) {
    body h2 {
        font-size: 24px;
        line-height: 26.54px;
    }

    body h3 {
        font-size: 22px;
        line-height: 24.54px;
    }

    body p {
        font-size: 16px;
    }
}

@media (max-width: 767px) {
    body h2 {
        font-size: 22px;
        line-height: 24.54px;
    }

    body h3 {
        font-size: 20px;
        line-height: 24.54px;
    }
}

body b {
    color: #FFD700;
}

.container-fluid {
    max-width: 100%;
    padding: 0 70px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
}

@media(max-width: 1440px) {
    .container-fluid {
        padding: 0 50px;
    }
}

@media(max-width: 1199px) {
    .container-fluid {
        padding: 0 25px;
    }
}

@media(max-width: 767px) {
    .container-fluid {
        padding: 0 15px;
    }
}


/* HeroBanner Css */
.hero-banner {
    background-image: url("./images/Group 59.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 678px;
    padding: 40px 0;
}

.hero-banner .container {
    height: 100%;
}

.hero-banner .hero-banner-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.hero-banner .langauages-selector {
    width: 100%;
    display: inline-block;
    padding-bottom: 28px;
}

.hero-banner .langauages-selector .langauages {
    float: right;
    width: 150px;
    cursor: pointer;
}

.hero-banner .langauages-selector select {
    background: #FFD700;
    border: 0;
    border-radius: 6px;
    padding: 5px 10px;
    float: right;
    cursor: pointer;
    font-family: 'proxima-nova', sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.49px;
    outline: 0;
}

.hero-banner .langauages-selector .text-img {
    cursor: pointer;
    font-family: 'proxima-nova', sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.49px;
    color: #000;
}

.hero-banner .langauages-selector .css-1okebmr-indicatorSeparator {
    background-color: unset !important;
}

.hero-banner .langauages-selector svg {
    fill: #000;
}

.hero-banner .langauages-selector .css-152aiu4-control {
    border: 0 !important;
    box-shadow: unset !important;
}

.hero-banner .langauages-selector select option {
    cursor: pointer;
}

.hero-banner .imgs {
    width: 100%;
    display: inline-block;
    text-align: center;
}

.hero-banner .imgs img {
    /* max-width: 100px; */
    max-width: 315px;
}

.hero-banner .hero-content {
    padding-top: 25px;
    width: 100%;
    display: inline-block;
    text-align: center;
}

.hero-banner .hero-content h2 {
    margin: 0;
    font-family: 'proxima-nova', sans-serif !important;
    font-size: 30px;
    font-weight: 700;
    line-height: 36.54px;
}

.hero-banner .hero-content .content {
    background: transparent;
    margin: 0;
    color: #fff;
    font-family: 'proxima-nova', sans-serif !important;
    font-size: 22px;
    font-weight: 800;
    line-height: 24.36px;
    width: 100%;
    padding: 12px 0 0;
}

.hero-banner .hero-content .notes {
    color: #EC1515;
    font-family: 'proxima-nova', sans-serif !important;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.36px;
    margin: 0;
    padding: 8px 0;
}

.hero-banner .buttons {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding-top: 20px;
}

.hero-banner .buttons .btn {
    background: #FFE000;
    color: #000;
    font-family: 'proxima-nova', sans-serif !important;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.92px;
    padding: 9px 20px;
    border: 0;
    outline: 0;
    border-radius: 25px;
}

.hero-banner .account-inquiry {
    margin: 0;
    font-family: 'proxima-nova', sans-serif !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.36px;
    text-align: center;
    color: #fff;
    padding: 20px 0 10px;
}

.hero-banner .links {}

.hero-banner .links .login-link,
.hero-banner .links .forget-link {
    font-family: 'proxima-nova', sans-serif !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.36px;
    text-align: center;
    text-decoration: none;
    display: block;
    color: #FFE000;
}

.hero-banner .links .login-link {
    padding-bottom: 10px;
}

.hero-banner .social_btn {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-banner .social_btn .social-imgs {
    width: 30px;
    margin-right: 20px;
}

.hero-banner .social_btn .social-imgs:last-child {
    margin-right: 0px;
}

.hero-banner .social_btn .social-imgs img {
    width: 100%;
    height: 100%;
}

@media(max-width: 1440px) {
    .hero-banner {
        height: 600px;
        padding: 25px 0;
    }

    .hero-banner .langauages-selector {
        padding-bottom: 15px;
    }

    .hero-banner .hero-content {
        padding-top: 15px;
    }

    .hero-banner .hero-content h2 {
        font-size: 26px;
        line-height: 33.54px;
    }

    .hero-banner .hero-content .content {
        font-size: 18px;
        line-height: 24.36px;
    }

    .hero-banner .hero-content .notes {
        font-size: 18px;
        line-height: 24.36px;
        padding: 6px 0;
    }

    .hero-banner .account-inquiry {
        font-size: 18px;
        line-height: 24.36px;
        padding: 15px 0 8px;
    }

    .hero-banner .links .login-link,
    .hero-banner .links .forget-link {
        font-size: 18px;
        line-height: 24.36px;
        padding-bottom: 8px;
    }

    .hero-banner .social_btn {
        padding-top: 15px;
    }

    .hero-banner .buttons {
        padding-top: 15px;
    }

    /* .hero-banner .imgs img {
        max-width: 75px;
    } */
}

@media(max-width: 1199px) {
    .hero-banner {
        height: 530px;
    }

    .hero-banner .hero-content {
        padding-top: 10px;
    }

    .hero-banner .hero-content h2 {
        font-size: 24px;
        line-height: 26.54px;
    }

    .hero-banner .hero-content .content,
    .hero-banner .hero-content .notes,
    .hero-banner .account-inquiry,
    .hero-banner .links .login-link,
    .hero-banner .links .forget-link,
    .hero-banner .buttons .btn {
        font-size: 16px;
    }

    .hero-banner .hero-content .content {
        padding: 6px 0 0;
    }

    .hero-banner .social_btn {
        padding-top: 8px;
    }

    .hero-banner .buttons {
        padding-top: 8px;
    }

    .hero-banner .buttons .btn {
        padding: 6px 15px;
    }
}

@media(max-width: 767px) {
    .hero-banner {
        background-position: bottom;
    }

    .hero-banner .imgs img {
        max-width: 275px;
    }

    .hero-banner .hero-content h2 {
        font-size: 22px;
        line-height: 24.54px;
    }
}

@media(max-width: 576px) {
    .hero-banner .imgs img {
        max-width: 215px;
    }
}


/* borderZone */
.border-zone {
    width: 100%;
    display: inline-block;
    height: 44px;
}

.border-zone img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Content Box View */
.content-boxview {
    padding: 80px 0;
}

.content-boxview .content-box {
    flex: 0 0 auto;
    width: 33.33333333%;
    padding: 0 12px;
    box-sizing: border-box;
}

.content-boxview .content-box .content-wrap {
    height: 100%;
}

.content-boxview .content-box h3 {
    font-family: 'proxima-nova', sans-serif !important;
    font-size: 30px;
    font-weight: 700;
    line-height: 36.54px;
    margin: 0;
    padding-bottom: 20px;
    color: #FFE000;
}

.content-boxview .content-box ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.content-boxview .content-box ul li {
    position: relative;
    color: #fff;
    font-family: 'proxima-nova', sans-serif !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.36px;
    padding-bottom: 18px;
    padding-left: 24px;
}

.content-boxview .content-box ul li:last-child {
    padding-bottom: 0;
}

.content-boxview .content-box ul li:before {
    content: '';
    background-image: url('images/leftAeerw.png');
    width: 15px;
    height: 15px;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 3px;
}

@media(max-width: 1440px) {
    .content-boxview .content-box h3 {
        font-size: 26px;
        line-height: 31.54px;
        padding-bottom: 15px;
    }

    .content-boxview .content-box ul li {
        font-size: 18px;
        padding-bottom: 16px;
    }
}

@media(max-width: 1199px) {
    .content-boxview {
        padding: 50px 0;
    }

    .content-boxview .content-box {
        width: 50%;
        margin-bottom: 24px;
    }

    .content-boxview .content-box h3 {
        font-size: 24px;
        line-height: 29.54px;
    }

    .content-boxview .content-box ul li {
        font-size: 16px;
    }
}

@media(max-width: 767px) {
    .content-boxview {
        padding: 25px 0;
    }

    .content-boxview .content-box {
        width: 100%;
    }
}


/* Features App COntent */
.features-app-content {
    padding: 20px 0 80px;
}

.features-app-content h3 {
    font-family: 'proxima-nova', sans-serif !important;
    font-size: 50px;
    font-weight: 700;
    line-height: 60.9px;
    text-align: center;
    margin: 0;
    color: #FFE000;
    padding-bottom: 12px;
    word-break: break-word;
}

.features-app-content p {
    font-family: 'proxima-nova', sans-serif !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.36px;
    text-align: center;
    margin: 0;
    color: #fff;
    padding-bottom: 50px;
}

.features-app-content .content-box {
    flex: 0 0 auto;
    width: 50%;
    padding: 0 12px;
    box-sizing: border-box;
}

.features-app-content .features {
    flex: 0 0 auto;
    width: 50%;
    padding: 0 12px;
    box-sizing: border-box;
    margin-bottom: 24px;
    /* height: 100%; */
}

.features-app-content .features .content-wrap {
    height: 100%;
    border: 2px dashed #FFD700;
    border-radius: 25px;
    /* padding: 30px; */
    text-align: center;
}

.features-app-content .features .content-wrap .content-title {
    padding: 30px;
}

.features-app-content .features .content-wrap h4 {
    font-family: 'proxima-nova', sans-serif !important;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.36px;
    color: #FFD700;
    margin: 0;
    padding-bottom: 12px;
}

.features-app-content .features .content-wrap p {
    text-align: center;
    padding-bottom: 0;
}

.features-app-content .app-img-box {
    text-align: center;
}

.features-app-content .app-img-box img {
    width: 100%;
    max-width: 477px;
}

@media(max-width: 1440px) {
    .features-app-content h3 {
        font-size: 44px;
        line-height: 50.9px;
    }

    .features-app-content p {
        font-size: 18px;
        padding-bottom: 30px;
    }

    .features-app-content .features .content-wrap .content-title {
        padding: 20px;
    }
}

@media (max-width: 1199px) {
    .features-app-content {
        padding-bottom: 50px;
    }

    .features-app-content h3 {
        font-size: 36px;
        line-height: 40.9px;
    }

    .features-app-content p {
        font-size: 16px;
    }

    .features-app-content .content-box {
        width: 100%;
        margin-bottom: 20px;
        order: 2;
    }

    .features-app-content .content-box:last-child {
        margin-bottom: 0;
        order: 1;
    }

    .features-app-content .app-img-box img {
        max-width: 477px;
    }

    .features-app-content .features .content-wrap .content-title {
        padding: 15px;
    }
}

@media (max-width: 767px) {
    .features-app-content {
        padding-bottom: 25px;
    }

    .features-app-content h3 {
        font-size: 30px;
        line-height: 32.9px;
    }

    .features-app-content .features {
        width: 100%;
    }

    .features-app-content .features:last-child {
        margin-bottom: 0;
    }
}

/* Footer Css */
.site-footer {
    background: #FFD700;
    padding: 20px 0;
}

.site-footer .copy-right,
.site-footer .footer-links {
    flex: 0 0 auto;
    width: 50%;
    padding: 0 12px;
    box-sizing: border-box;
}

.site-footer .copy-right {
    font-family: 'proxima-nova', sans-serif !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.92px;
    text-align: left;
    color: #000;
}

.site-footer .footer-links {
    margin: 0;
    /* padding: 0; */
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: end
}

.site-footer .footer-links li {
    position: relative;
}

.site-footer .footer-links li a {
    font-family: 'proxima-nova', sans-serif !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.92px;
    color: #000;
    text-decoration: none;
}

.site-footer .footer-links li:after {
    content: '';
    border-right: 1px solid #000;
    margin: 0 5px;
}

.site-footer .footer-links li:last-child:after {
    content: unset;
}

@media(max-width: 1199px) {
    .site-footer {
        padding: 15px 0;
    }

    .site-footer .copy-right {
        font-size: 16px;
    }

    .site-footer .footer-links li a {
        font-size: 16px;
    }
}

@media(max-width: 991px) {

    .site-footer .copy-right,
    .site-footer .footer-links {
        width: 100%;
    }

    .site-footer .copy-right {
        text-align: center;
        padding-bottom: 5px;
    }

    .site-footer .footer-links {
        justify-content: center;
    }
}

@media(max-width: 576px) {
    .site-footer .footer-links {
        flex-wrap: wrap;
    }
}

/* Social Sticky */
body .storeButtonDiv {
    position: fixed;
    bottom: 70px;
    right: 70px;
}

@media(max-width: 1440px) {
    body .storeButtonDiv {
        right: 50px;
    }
}

@media(max-width: 1199px) {
    body .storeButtonDiv {
        right: 25px;
    }
}

@media(max-width: 991px) {
    body .storeButtonDiv {
        bottom: 90px;
    }
}

@media(max-width: 767px) {
    body .storeButtonDiv {
        right: 15px;
    }
}


/* Privacy Policy */
.common-content {
    padding: 80px 0;
}

.common-content .logo {
    width: 100px;
    margin-bottom: 15px;
}

.common-content h2,
.common-content h3,
.common-content h4 {
    margin: 0;
    padding-bottom: 15px;
    text-align: left !important;
}

.common-content .pl-0 {
    padding-left: 0 !important;
}

.common-content .list-style-none {
    list-style-type: none;
}

.common-content a {
    cursor: pointer;
    text-decoration: underline;
}

.common-content .border-bottom {
    border-bottom: 2px solid #fff;
    margin: 15px 0 25px;
}

.common-content ul,
.common-content ol {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: left !important;
    padding-bottom: 10px;
}

.common-content .ol-main {
    padding-left: 20px;
    list-style-type: auto;
}

.common-content .ul-main {
    padding-left: 20px;
    list-style-type: disc;
}

.common-content .ul-main li::marker {
    color: #FFD700;
}

.common-content ul li,
.common-content ol li {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    line-height: 21.92px;
    padding-bottom: 8px;
}

.common-content ul li:last-child,
.common-content ol:last-child {
    padding-bottom: 0;
}

.common-content ul li::marker,
.common-content ol li::marker {
    color: #FFD700;
}

.common-content ul li ul,
.common-content ol li ol {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.92px;
    padding-top: 8px;
    list-style-type: disc;
    padding-left: 40px;
}

.common-content ul li ul li::marker,
.common-content ol li ol li::marker {
    color: #FFD700;
}

.common-content p {
    margin: 0;
    padding-bottom: 15px;
    text-align: left !important;
}

@media(max-width: 1199px) {

    .common-content ul li,
    .common-content ol li {
        font-size: 18px;
        line-height: 21.92px;
    }

    .common-content {
        padding: 50px 0;
    }
}

@media(max-width: 767px) {
    .common-content .privacyDiv {
        width: 100%;
    }

    .common-content ul li,
    .common-content ol li {
        font-size: 16px;
        line-height: 21.92px;
    }

    .common-content {
        padding: 25px 0;
    }

    .common-content h2,
    .common-content h3,
    .common-content h4 {
        padding-bottom: 10px;
    }

    .common-content p {
        padding-bottom: 10px;
    }
}
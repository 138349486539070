body {
  background-color: #1D1D1D;
  font-family: proxima-nova, sans-serif;
  margin: 0;
  height: 100%;
  color: #323232;
  overflow-x: hidden;
}

input::placeholder {
  color: #343434;
}

.storeButtons {
  width: 120px;
  display: block;
  margin-top: 10px;
  cursor: pointer;
}

.storeButtonDiv {
  position: fixed;
  bottom: 25px;
  right: 25px;
}


 
.social_link_btn {
  width: 30px;
  display: block;
  margin-top: 10px;
  cursor: pointer;
  padding-right: 10px;
  }

.socialButtonDiv {
  display: flex;
  justify-content: end;
 

 }


.trialDivOnboarding {
  display: inline-block;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 5px;
}

.trialMessageOnboarding {
  color: white;
  margin: 0;
  font-size: 12pt;
  text-align: center;
  text-decoration: underline;
}

.buttonOnBoarding {
  font-family: proxima-nova, sans-serif;
  background-color: #FFE000;
  border: none;
  width: 110px;
  height: 40px;
  border-radius: 100px;
  font-size: 10pt;
  font-weight: 500;
  cursor: pointer;
  margin-top: 10px;
}

.introText {
  color: white;
}
.introTexts {
  color: white;
  margin-right: 0.5rem;
}
.datePicker{
  width: 20px;
}

.introTextRed {
  font-size: 10pt;
  color: rgb(255, 40, 40);
}
.tutorialLable {
  display: flex;
   
 }

.accountInquiry {
  color: white;
}

.landingButton {
  font-family: proxima-nova, sans-serif;
  color: rgb(255, 40, 40);
  background-color: #FFE000;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  border-radius: 100px;
  font-size: 12pt;
  font-weight: 500;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 10px;
}

p {
  font-size: 9.5pt;
}

.link {
  font-size: 9.5pt;
  text-decoration: underline;
  color: #FFE000;
}

.onBoardingInput {
  font-family: proxima-nova;
  width: 190px;
  padding: 14px 14px;
  font-size: 10pt;
  font-weight: 300;
  border-radius: 2px;
  border: none;
  color: #7E7E7E;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
}

.categorySelectOnBoarding {
  font-family: proxima-nova;
  font-size: 11pt;
  width: 218px;
  color: #7E7E7E;
  margin: 0 auto;
  margin-bottom: 15px;
}

.css-1wa3eu0-placeholder {
  color: #343434 !important;
  font-weight: 400;
  font-size: 12pt;
  margin-left: 4px !important;
}

.css-26l3qy-menu {
  text-align: left;
}

.locationMargin {
  margin-top: 15px;
}

.footer {
  margin-top: 30px;
  margin-bottom: 30px;
}

.footerLink {
  color: white;
  text-decoration: none !important;
  margin-right: 15px;
}

.aboutUsDiv {
  width: 600px;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
}

/*

.css-b8ldur-Input{
  margin: 0px !important;
  padding: 0px !important;
}

.css-1wa3eu0-placeholder {
  margin-left: 4px !important;
  color: rgb(193, 193, 193) !important;
  font-weight: 300 !important;
}

.css-yk16xz-control {
  border-radius: 3px !important;
}





.css-1hwfws3{
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.css-1rhbuit-multiValue{
margin: 0 !important;
margin-left: 3px !important;
}

.inputDiv{
    margin-bottom: 30px;
}

*/

.regTitle {
  font-size: 18pt;
  font-weight: 600;
  margin-bottom: 30px;
  color: white;
}

.logoOnBoarding {
  width: 100px;
}

.logoDiv {
  float: left;
  z-index: 1;
}

.regLogo {
  width: 80px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.pickSubLogo {
  margin-left: 30px;
  position: absolute;
  width: 60px;
  left: 0;
  top: 30px;
}

.title {
  font-size: 27pt;
  font-weight: bold;
  color: #FFE000;
  margin-bottom: 20px;
}

.introText {
  font-weight: 300;
}

.innerOnBoarding {
  margin-top: 170px;
  width: 500px;
}

.outerOnBoarding {
  float: left;
  margin-left: 50px;
  margin-top: 40px;
  width: 100%;
  min-height: 100%;
  position: relative;
}

.innerReg {
  text-align: center;
}

 

.logInLink {
  display: block;
  margin-bottom: 10px;
}

.social_btn {
  flex-direction: row;


}

.pickSubOuter {
  text-align: center;
}

.options {
  display: flex;
  margin: 0 auto;
  width: 825px;
}

.subOuterBoarding {
  text-align: center;
  width: 255px;
  background-color: #F4F4F4;
  border-radius: 5px;
  padding-bottom: 0px;
  margin-right: 30px;
}

.bgInner {
  background-color: rgb(228, 228, 228);
  margin: 15px;
  border-radius: 3px;
}

.subInnerOnBoarding {
  width: 170px;
  padding: 20px;
  text-align: center;
  display: inline-block;
}

.subTitleDiv {
  padding: 15px;
  background-color: #1D1D1D;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 0px;
}

.subTitle {
  font-size: 14pt;
  font-weight: 400;
  color: #FFE000;
  margin: 0;
}

.costTitle {
  font-size: 24pt;
  font-weight: 500;
  margin-bottom: 12.5px;
  margin-top: 20px;
}

.subTextOnBoarding {
  font-size: 9pt;
  font-weight: 400;
  margin-bottom: 25px;
}

.perkDiv {
  text-align: left;
}

.perkText {
  font-size: 9pt;
  display: inline;
}

.perkRow {
  margin-bottom: 5px;
}

.infoIcon {
  width: 17px;
  float: right;
}

.monthSpan {
  font-size: 12pt;
  font-weight: 400;
}

.unactive {
  color: #A5A5A5;
}

.subBtnOnBoarding {
  font-family: proxima-nova, sans-serif;
  background-color: #FFE000;
  border: none;
  width: 110px;
  height: 40px;
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 10pt;
  border-radius: 100px;
  font-weight: 400;
  color: #1D1D1D;
}

.errorText {
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
  color: white;
  width: 220px;
}

.privacyDiv {
  width: 800px;
  margin: 0 auto;
  margin-bottom: 70px;
}

.privacyTitle {
  color: white;
  font-size: 20pt;
  font-weight: 600;
}

.privacyDesc {
  color: white;
  font-weight: 300;
  font-size: 11pt;
  text-align: center;
  margin-bottom: 0px;
}

.privacyQ {
  font-size: 11pt;
  color: white;
  font-weight: 600;
  text-align: left;
  margin-top: 30px;
}

.privacyA {
  color: white;
  font-weight: 300;
  text-align: left;
}

.privacyLink {
  color: #FFE000;
}

.aboutDesc {
  color: white;
  font-weight: 300;
  font-size: 10pt;
  text-align: left;
  margin-bottom: 0px;
}

.aboutDescBold {
  color: white;
  font-weight: 500;
  font-size: 11pt;
  text-align: left;
  margin-bottom: 0px;
}

.aboutDescYellow {
  margin-top: 30px;
  color: #FFE000;
  font-weight: 500;
  font-size: 13pt;
  text-align: center;
  margin-bottom: 30px;
}

/**
 * The geosuggest module
 * NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
 */

.geosuggest {
  font-size: 18px;
  font-size: 10pt;
  position: relative;
  width: 215px;
  margin: 0 auto;
  text-align: left;
}

.geosuggest__input {
  font-family: proxima-nova, sans-serif;
  font-size: 11pt;
  border-radius: 3px;
  margin-left: -2px;
  width: 190px;
  border: solid 1px #ccc;
  padding: 14px 14px;
}

.geosuggest__input:focus {
  border-color: #267dc0;
  box-shadow: 0 0 0 transparent;
}

.geosuggest__suggests {
  position: absolute;
  top: 100%;
  width: 215px;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 2px solid #267dc0;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
   * A geosuggest item
   */

.geosuggest__item {
  color: black;
  font-size: 18px;
  font-size: 10pt;
  padding: .5em .65em;
  cursor: pointer;
}

.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}

.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}

.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}

.geosuggest__item__matched-text {
  font-weight: bold;
}

@media screen and (max-width: 1025px) {
  .pickSubLogo {
    display: none;
  }

  .bgInner {
    margin: 20px;
    display: inline-block;
  }

  .subOuterBoarding {
    margin: 0 auto;
    margin-bottom: 20px;

  }

  .options {
    display: block;
    margin: 0 auto;
    width: initial;
  }

  .geosuggest {
    width: 265px;
  }

  .geosuggest__input-wrapper {
    width: 240px;
  }

  .geosuggest__input {
    height: 35px;
    width: 240px;
    font-size: 14pt !important;
  }

  .css-1wa3eu0-placeholder {
    font-size: 14pt !important;
  }

  .css-1hwfws3 {
    height: 60px;
  }

  .css-yk16xz-control {
    height: 60px;
    width: 100%;
  }

  .categorySelectOnBoarding {
    width: 270px;
    font-size: 14pt;
  }

  .onBoardingInput {
    width: 240px;
    height: 35px;
    font-size: 14pt;
  }

  .regTitle {
    font-size: 24pt;
  }

  .buttonOnBoarding {
    font-size: 14pt;
    width: 140px;
    height: 60px;
    margin-bottom: 20px;
  }

  .logoDiv {
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
  }

  .logoOnBoarding,
  .regLogo {
    width: 100px;
    margin-top: 20px;
    margin-bottom: 10px;
  }



  .outerOnBoarding {
    width: 90%;
    margin-left: 5%;
  }

  .innerOnBoarding {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 40px;
  }

  .privacyDiv {
    width: 90%;
    margin-bottom: 20px;
  }

  .aboutUsDiv {
    width: 90%;
    margin-bottom: 20px;
  }
}


.timeBox{
  display: flex;
  align-items: center;
}

.timeBox b{
  padding: 0px 5px;
}
.MuiList-root:after{
  height: 0!important;
}

.timeBox .MuiStack-root{
  display: block;
  overflow: unset;
  padding-top: 0;
  width: 38%;
}
.timeBox  label {
  width: 85px;
    margin-right: 0;
    float: unset;
    display: block;
}
.timeBox .datpicker-content{
  width: calc(100% - 85px);
  display: flex;
  align-items: center;
}
.timeBox .datpicker-content.justify-content-between{
  justify-content: space-between;
}
.timeBox .MuiStack-root .MuiFormControl-root{
  min-width: 100%;
}
.timeBox .MuiStack-root .MuiOutlinedInput-root{
position: relative;
height: 30px;
font-size: 0.8rem;
text-align: left;
display: block; 
padding: 0;
}

.timeBox .MuiStack-root .MuiOutlinedInput-root.Mui-focused  {
  box-shadow: 0 1px 5px #ccc;
    background-color: #fff;
    border-radius: 4px;
}
.timeBox .MuiStack-root .MuiOutlinedInput-root input{
height: 30px;
padding-top: 0;
padding-bottom: 0;
padding-left: 0px;
padding-right: 0px;
text-align: center;
}

.timeBox .MuiStack-root .MuiOutlinedInput-root fieldset{
top: 0;
border-color: rgba(0, 0, 0, 0.23);
}

.timeBox .MuiStack-root .MuiOutlinedInput-root fieldset legend {
  line-height: 0;
}

.timeBox .MuiStack-root .MuiOutlinedInput-root .MuiInputAdornment-root{
position: absolute;
left: 0;
right: 0;
width: 100%;
height: 100%;
display: block;
top: 0;
margin: 0;
max-height: 100%;
}
.timeBox .MuiStack-root .MuiOutlinedInput-root .MuiInputAdornment-root button{
padding: 0;
width: 100%;
height: 100%;
display: block;
outline: 0;
box-shadow: unset;
}
.timeBox .MuiStack-root .MuiOutlinedInput-root .MuiInputAdornment-root button svg{
visibility: hidden;
}

@media(min-width: 1025px){
  .MuiPickersLayout-actionBar{
    display: none!important;
  }
}

.MuiMultiSectionDigitalClock-root {
  /* width: 80px!important; */
}

.MuiMultiSectionDigitalClock-root .MuiMenuItem-root{
  font-size: 0.8rem!important;
  margin: 4px 0!important;
  padding: 0 6px !important;
  display: block!important;
  /* width: 100%; */
  text-align: center;
}

.MuiMultiSectionDigitalClock-root .MuiMenuItem-root.Mui-selected{
background: #FFE000;
color: #1D1D1D;
}
.MuiMultiSectionDigitalClock-root .MuiMenuItem-root:hover,
.MuiMultiSectionDigitalClock-root .MuiMenuItem-root.Mui-selected:hover{
  background-color: #FFE000;
color: #1D1D1D;
}
.MuiButtonBase-root .MuiMenuItem-root .MuiMenuItem-gutters .Mui-selected .MuiMenuItem-root .MuiMenuItem-gutters .Mui-selected  {  background-color: #d29419;
  color: #fff;
}

.MuiMultiSectionDigitalClock-root:nth-child(1){
  overflow-y: auto;
}

.MuiMultiSectionDigitalClock-root:nth-child(2){
  overflow: hidden!important;
}
.MuiMultiSectionDigitalClock-root:nth-child(2) .MuiMenuItem-root{
  width: 100%;
}